@use '../../themes';
.optionalArrow {
    height: 48px;
    width:34px;
    margin-right: -20px;
    position: relative;
    cursor:pointer;
    svg {
        stroke: themes.$custom-silver-light;
        stroke-width: 3px;
        path{
            filter:url(#filter19278);
        }
        fill: themes.$custom-green;
        stroke-width: 2.5px;
        position: absolute;
        z-index: 1;
        left: -7px;
    }
}