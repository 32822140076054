@use "../../../themes";
@use '../../../mixins';

.OptionsGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: fit-content;
    /*padding-right: 15px;*/
    margin-left: -22px;
    @include mixins.arrow-back-colors(themes.$custom-green, #fff);
    
    &.Disabled {
        display: flex;
        @include mixins.arrow-back-colors(themes.$custom-silver, #fff);
        /*@include arrow-front-colors($custom-silver, #fff);*/
    }
    &.NoOptionEnabled {
        display: none!important;
        @include mixins.arrow-back-colors(themes.$custom-silver-light, #fff);
    }
    .Optional, .NotExists {
        padding-right: 13px;
        margin-left: -7px;
        @include mixins.arrow-back-colors(themes.$custom-green-light, #fff);
        @include mixins.arrow-front-colors(themes.$custom-green-light, #fff);
            position: relative;
            display: flex;
            zoom: 1;
            /*background: $custom-green-light;*/
            margin-bottom: 0;
            line-height: 42px;
            color: #fff;
            margin-left: 3px;
            padding-right: 0;
            font-size: 14px;
            margin-right: -20px;
            cursor: pointer;
            span {
                text-indent: 13px;
                background: themes.$custom-green-light;
                border-top: 3px solid #fff;
                border-bottom: 3px solid #fff;
            }
            input {
                display: none;
            }
            &:hover,
            &.Enabled {
                @include mixins.arrow-back-colors(themes.$custom-green-bold, #fff);
				@include mixins.arrow-front-colors(themes.$custom-green-bold, #fff);
                span {
                    background: themes.$custom-green-bold;
                }
            }
    }
}
