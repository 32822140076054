@use "../../../themes";
@use '../../../base';
@use '../../../mixins';
.ClassTypeId{
    display: flex;
    span.unselect{
        position: relative;
        top:-10px;
        right:29px
    }

    &.Highlited {
        @include mixins.arrow-back-colors(themes.$custom-red, #fff);
		@include mixins.arrow-front-colors(themes.$custom-red, #fff);
        .nice-select{
            border-radius: unset;
            color: #fff;
            background-color: themes.$custom-red;
            border-color: #fff;
        }
        &.VariableSelected{
            @include mixins.arrow-front-colors(themes.$custom-red, themes.$custom-green-bold);
            @include mixins.arrow-back-colors(themes.$custom-red, themes.$custom-green-bold);
            .nice-select{
                border-color: themes.$custom-green-bold;
            }
        }
	}


}