@use '../base';
@use '../themes';
.link-where-bottom {
    .upper-vertical, .horizontal, .lower-vertical{
        height: 3px;
        position: absolute;
        background-color: themes.$custom-red;
        display: block;
        z-index: 0;
    }
    .horizontal{
        z-index: 1; // span or should be overlayd over .lower-vertical
        span {
            position: relative;
            top: -10px;
            left: -35px;
            background: #fff;
            border: 2px solid themes.$custom-red ;
            border-radius: 15px;
            height: 30px;
            line-height: 20px;
            padding: 2px 10px;
            width: fit-content;
        }
    }
    &.optionalEnabled{
        .upper-vertical, .horizontal, .lower-vertical{
            height: 3px;
            position: absolute;
            // rgba(255,255,255,0) is white transparent = completely transparent
            background: repeating-linear-gradient( 90deg, rgba(255,255,255,0), rgba(255,255,255,0) 8px, themes.$custom-red 8px, themes.$custom-red 16px);
            display: block;
        }
        span{
            border: 2px dashed themes.$custom-red
        }
    }

    &.notExistsEnabled {
        .upper-vertical, .horizontal, .lower-vertical{
            // rgba(255,255,255,0) is white transparent = completely transparent
		    background: repeating-linear-gradient( 90deg, rgba(255,255,255,0), rgba(255,255,255,0) 8px, #000 8px, #000 16px);
        }
		span {
			border: 3px dashed #000;
			border-radius: 15px;
		}
	}
}