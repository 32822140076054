@use '../../../base';
@use '../../../themes';
@use 'sass:math';
.ActionsGroup { // acts als hover div
    position: absolute;
    display: block;
    top: 100%;
    width: 100%;
    height: 46px;
    z-index:1;
    
    &:hover .ActionAnd {display: block;}

    &>div {
        position: absolute;
    }
    .ActionAnd {
        display:none;
        left: math.div(base.$margin-children-li - 45, 2);
        left: 36px;
        top: math.div(base.$margin-component, 2);
        line-height: 46px;
        /*width: 46px;*/
        text-align: center;
        .trait-and-bottom {
            position: absolute;
            top: -14px;
            left: -1px;
            border-left: 3px solid themes.$custom-red ;
            margin-left: -1px;
            height: 15px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top:-10px;
                left:-6px;
                width: 9px;
                height: 9px;
                -moz-box-shadow: 0px 6px 3px -3px #cfcfcf;
                -webkit-box-shadow: 0px 6px 3px -3px #cfcfcf;
                -o-box-shadow: 0px 6px 3px -3px #cfcfcf;
                box-shadow: 0px 6px 3px -3px #cfcfcf;
                filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=180, Strength=3);
            }
        }
        a {
            cursor: pointer;
            display: block;
            background: #fff;
            border: 2px solid themes.$custom-red ;
            border-radius: 15px;
            height: 30px;
            line-height: 20px;
            padding: 2px 10px;
            transform: translate(-50%, 0);
            &:hover {
                color: themes.$custom-red ;
            }
        }
    }
}