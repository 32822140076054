@use "../themes";
@use '../base';

.builder-section{
    .reset-wrapper {
		position: relative;
		height: 0;
		max-width: 100%;
		margin: 0 auto;
		&>.reset-form {
			position: absolute;
			right: 0;
			top: 1px;
			width: 54px;
			height: 54px;
			margin-top: base.$margin-component;
			margin-bottom: base.$margin-component;
			border: 1px solid #ddd;
			border-radius: 5px;
			background: #fff;
			box-shadow: 0px 2px 3px 0px #cfcfcf;
			a {
				display: block;
				margin: 5px;
				cursor: pointer;
			}
		}
	}
    display: block;
	padding: 0 base.$margin-component;
}
