@import "nice-select.css";
@import "~easy-autocomplete/dist/easy-autocomplete.css";
@import "~@chenfengyuan/datepicker/dist/datepicker.min.css";
/**
 * Color of the criteria arrows (orange)
 * primary-color-main
 **/
/**
 * Color of the "unselect" buttons in criterias (dark orange)
 * primary-color-important
 **/
/**
 * Color of a selected value in a criteria (light orange)
 * primary-color-medium
 **/
/**
 * variable selector background & tooltip background (even lighter orange)
 * primary-color-light
 **/
/** 
 * Color of the options arrow (optional / negation) (strong green)
 * secondary-color-main
 **/
/**
 * Color of the options arrow when not selected (light green)
 * secondary-color-light
 **/
/** 
 * Color of the highlight of arrows
 * secondary-color-important
 **/
/**
 * Color of variable d&d grip, disabled query button, disabled options
 **/
/**
 *
 **/
/**
 * Color of line between query and variable selection
 **/
/**
 * Default text color
 **/
.submitSection {
  height: 41px;
  background: rgb(107, 215, 171);
  position: relative;
  margin: 0 auto;
  width: 100%;
}
.submitSection a {
  position: absolute;
  display: flex;
  top: 14px;
  width: fit-content;
  right: 30px;
  padding: 10px;
  padding-left: 12px;
  border-radius: 5px;
  background: rgb(2, 184, 117);
  box-shadow: 0px 2px 3px 0px #cfcfcf;
  cursor: pointer;
  z-index: 2;
}
.submitSection a svg {
  display: block;
  max-width: 34px;
  height: 34px;
}
.submitSection a svg path {
  fill: #FFFFFF;
  width: auto;
  height: auto;
}
.submitSection a svg.playLoader {
  display: none;
  max-width: 44px;
  height: 44px;
}
.submitSection a.submitDisable {
  background: rgb(230, 230, 230);
  cursor: default;
}
.submitSection a.submitDisable svg path {
  fill: rgb(167, 167, 167);
}
.submitSection a.submitDisable.loadingEnabled {
  padding: 4px;
}
.submitSection a.submitDisable.loadingEnabled svg.playIcon {
  display: none;
}
.submitSection a.submitDisable.loadingEnabled svg.playLoader {
  display: block;
}
.submitSection a.submitDisable.loadingEnabled svg.playLoader path {
  fill: rgb(2, 184, 117);
}

.builder-section {
  display: block;
  padding: 0 30px;
}
.builder-section .reset-wrapper {
  position: relative;
  height: 0;
  max-width: 100%;
  margin: 0 auto;
}
.builder-section .reset-wrapper > .reset-form {
  position: absolute;
  right: 0;
  top: 1px;
  width: 54px;
  height: 54px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px #cfcfcf;
}
.builder-section .reset-wrapper > .reset-form a {
  display: block;
  margin: 5px;
  cursor: pointer;
}

.variablesSelection {
  background: rgb(255, 231, 203);
  margin-bottom: 22px;
  border-top: 2px solid #8D8D8D;
  background: rgb(255, 231, 203);
  margin-bottom: 22px;
  border-top: 2px solid #8D8D8D;
  /* Rounded sliders */
}
.variablesSelection .flexWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.variablesSelection .linesWrapper {
  height: 0;
  overflow: hidden;
}
.variablesSelection.displayed .linesWrapper {
  height: auto;
}
.variablesSelection.displayed .VariableSelectorDisplay a {
  margin-top: -22px;
  transition: margin-top 0.7s ease;
}
.variablesSelection .VariableSelectorDisplay {
  background: rgb(255, 210, 157);
  height: 41px;
  position: relative;
}
.variablesSelection .VariableSelectorDisplay a {
  position: absolute;
  left: 50%;
  display: block;
  height: 44px;
  width: 44px;
  margin: 0 auto;
  margin-top: 18px;
  margin-left: -22px;
  border-radius: 22px;
  background: rgb(255, 210, 157);
  line-height: 1;
  padding: 10px;
  transition: margin-top 0.7s ease;
  cursor: pointer;
}
.variablesSelection .VariableSelectorDisplay a .arrow-icon {
  margin-top: 4px;
  display: inline;
}
.variablesSelection .line1,
.variablesSelection .line2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  margin: 0 auto;
  border: 1px solid #ddd;
  background: #fff;
  max-width: 96%;
}
.variablesSelection .line1 > div.variablesOtherSelect,
.variablesSelection .line2 > div.variablesOtherSelect {
  display: block;
}
.variablesSelection .line1 {
  border-radius: 5px 5px 0 0;
  border-bottom: 0px solid transparent;
  min-height: 61px;
  margin-top: 41px;
}
.variablesSelection .line1 .sortableItem {
  padding: 10px;
  display: inline-block;
}
.variablesSelection .line1 .sortableItem:first-child {
  min-width: 140px;
}
.variablesSelection .line1 .variablesOtherSelect > div:first-child {
  background: lightgray;
  height: 100%;
  display: block;
  float: left;
}
.variablesSelection .line1 .variableSelected {
  padding: 3px 7px 3px 2px;
  border: 1px solid gray;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  background: #fff;
  align-items: center;
  width: fit-content;
  min-width: 110px;
}
.variablesSelection .line1 .variableSelected .variable-handle {
  display: inline-block;
  padding: 0 3px;
  height: 24px;
  color: transparent;
  /*border-left: 2px dashed lightgray;
  border-right: 2px dashed lightgray;*/
  cursor: grab;
}
.variablesSelection .line1 .variableSelected .variable-handle svg {
  width: 15px;
  height: 24px;
}
.variablesSelection .line1 .variableSelected .variable-handle svg path {
  stroke: rgb(167, 167, 167);
  stroke-width: 0.264583;
}
.variablesSelection .line1 .variableSelected input {
  line-height: 30px;
  padding: 1px;
  min-width: 3ch;
  margin-left: 5px;
  border: 0px;
  text-align: center;
}
.variablesSelection .line1 .variableSelected input:invalid {
  border: 2px dashed red;
}
.variablesSelection .line1 .variableSelected [contenteditable=true]:active,
.variablesSelection .line1 .variableSelected [contenteditable=true]:focus {
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0 5px;
  outline: none;
}
.variablesSelection .line2 {
  margin-bottom: 41px;
  border-radius: 0 0 5px 5px;
  justify-content: space-between;
  border-top: 1px solid darkgray;
  line-height: 20px;
  -moz-box-shadow: 0px 2px 3px 0px #cfcfcf;
  -webkit-box-shadow: 0px 2px 3px 0px #cfcfcf;
  -o-box-shadow: 0px 2px 3px 0px #cfcfcf;
  box-shadow: 0px 2px 3px 0px #cfcfcf;
  color: #666;
}
.variablesSelection .line2 .variablesOrdersSelect {
  background: lightgray;
  padding: 0 10px;
  min-width: 148px;
}
.variablesSelection .line2 .variablesOrdersSelect strong {
  font-size: 12px;
}
.variablesSelection .line2 .variablesOrdersSelect a {
  cursor: pointer;
}
.variablesSelection .line2 .variablesOrdersSelect a svg {
  width: 20px;
  height: 20px;
}
.variablesSelection .line2 .variablesOrdersSelect a.selected {
  cursor: default;
}
.variablesSelection .line2 .variablesOrdersSelect a.selected svg path {
  fill: rgb(250, 136, 3);
}
.variablesSelection .line2 .variablesOptionsSelect {
  display: flex;
  text-align: right;
  padding: 5px 5px 5px 5px;
  font-size: 14px;
  font-weight: bolder;
}
.variablesSelection .line2 .variablesOptionsSelect p {
  border-top: 10px;
  margin-bottom: auto;
  margin-right: 10px;
}
.variablesSelection .line2 a {
  display: inline-block;
  padding: 5px;
  color: #666;
}
.variablesSelection .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin-bottom: 0;
}
.variablesSelection .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.variablesSelection .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.variablesSelection .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.variablesSelection input:checked + .slider {
  background-color: rgb(255, 210, 157);
}
.variablesSelection input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}
.variablesSelection input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
  background-color: rgb(250, 136, 3);
}
.variablesSelection .slider.round {
  border-radius: 26px;
  border: 1px solid #555;
}
.variablesSelection .slider.round:before {
  border-radius: 50%;
  border: 1px solid #555;
}

ul.componentsListe {
  padding: 0;
  width: 100%;
  margin: 0 auto;
}
ul.componentsListe,
ul.componentsListe ul {
  list-style-type: none;
  padding: 0;
  background: transparent;
  clear: both;
  border: 0 solid transparent;
}
ul.componentsListe ul {
  /*li>div {
  	margin-left: 46px;
  }
  li li>div {
  	margin-left: 92px;
  	*
  }*/
}
ul.componentsListe ul li li.OnEdit > div > div > .ActionWhere.ShowOnEdit {
  display: none;
}
ul.componentsListe li.groupe.optional-enabled .CriteriaGroup {
  border: 3px dashed rgb(250, 136, 3);
}
ul.componentsListe li.groupe.optional-enabled ul.childsList .ActionAnd > .trait-and-bottom {
  border-left: 3px dashed rgb(250, 136, 3);
}
ul.componentsListe li.groupe.optional-enabled ul.childsList .ActionAnd > a {
  border: 2px dashed rgb(250, 136, 3);
}
ul.componentsListe li.groupe.optional-enabled ul.childsList > li.hasAnd > span {
  border-left: 3px dashed rgb(250, 136, 3);
}
ul.componentsListe li.groupe.optional-enabled ul.childsList > li.hasAnd > span > span {
  border: 2px dashed rgb(250, 136, 3);
}

ul.componentsListe li.groupe:first-child > .CriteriaGroup {
  width: calc(100% - 54px - 20px);
}

li.groupe {
  margin: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  display: block;
  clear: both;
  /*overflow: hidden;*/
  /*first line*/
}
li.groupe > li.groupe {
  margin-left: 75px;
}

/* Structure flex */
.CriteriaGroup {
  border: 1px solid #ddd;
  display: flex;
  position: relative;
  border-radius: 5px;
  background: #fff;
  margin-top: 30px;
  margin-bottom: 80px;
  padding: 2px 0 2px 5px;
  clear: both;
  -moz-box-shadow: 0px 2px 3px 0px #cfcfcf;
  -webkit-box-shadow: 0px 2px 3px 0px #cfcfcf;
  -o-box-shadow: 0px 2px 3px 0px #cfcfcf;
  box-shadow: 0px 2px 3px 0px #cfcfcf;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=180, Strength=3);
  /* Unselect to graphique  */
}
.CriteriaGroup .flexWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.CriteriaGroup .componentBackArrow svg {
  height: 48px;
}
.CriteriaGroup .componentBackArrow svg path {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2.5px;
}
.CriteriaGroup .componentFrontArrow svg {
  height: 48px;
}
.CriteriaGroup .componentFrontArrow svg path {
  fill: transparent;
  stroke: rgb(230, 230, 230);
  stroke-width: 3px;
}
.CriteriaGroup .componentFrontArrow svg rect {
  fill: #fff;
}
.CriteriaGroup.optionalEnabled {
  border: 3px dashed rgb(250, 136, 3);
}
.CriteriaGroup.notExistsEnabled {
  border: 3px dashed #000;
}
.CriteriaGroup span.unselect {
  /*position: absolute;*/
  display: block;
  position: absolute;
  top: 15px;
  right: 10px;
  width: 22px;
  height: 22px !important;
  padding: 0 0;
  text-align: center;
  cursor: pointer;
  color: rgb(182, 97, 0);
  background: #fff !important;
  line-height: 22px;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 11px;
  z-index: 2;
}

/* When a value is selected or Where is enabled */
li.groupe.completed > .CriteriaGroup {
  margin-bottom: 30px;
}

.OptionsGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: fit-content;
  /*padding-right: 15px;*/
  margin-left: -22px;
}
.OptionsGroup .componentBackArrow svg {
  height: 48px;
}
.OptionsGroup .componentBackArrow svg path {
  fill: rgb(107, 215, 171);
  stroke: #fff;
}
.OptionsGroup.Disabled {
  display: flex;
  /*@include arrow-front-colors($custom-silver, #fff);*/
}
.OptionsGroup.Disabled .componentBackArrow svg {
  height: 48px;
}
.OptionsGroup.Disabled .componentBackArrow svg path {
  fill: rgb(167, 167, 167);
  stroke: #fff;
}
.OptionsGroup.NoOptionEnabled {
  display: none !important;
}
.OptionsGroup.NoOptionEnabled .componentBackArrow svg {
  height: 48px;
}
.OptionsGroup.NoOptionEnabled .componentBackArrow svg path {
  fill: rgb(230, 230, 230);
  stroke: #fff;
}
.OptionsGroup .Optional, .OptionsGroup .NotExists {
  padding-right: 13px;
  margin-left: -7px;
  position: relative;
  display: flex;
  zoom: 1;
  /*background: $custom-green-light;*/
  margin-bottom: 0;
  line-height: 42px;
  color: #fff;
  margin-left: 3px;
  padding-right: 0;
  font-size: 14px;
  margin-right: -20px;
  cursor: pointer;
}
.OptionsGroup .Optional .componentBackArrow svg, .OptionsGroup .NotExists .componentBackArrow svg {
  height: 48px;
}
.OptionsGroup .Optional .componentBackArrow svg path, .OptionsGroup .NotExists .componentBackArrow svg path {
  fill: rgb(151, 227, 196);
  stroke: #fff;
}
.OptionsGroup .Optional .componentFrontArrow svg, .OptionsGroup .NotExists .componentFrontArrow svg {
  height: 48px;
}
.OptionsGroup .Optional .componentFrontArrow svg path, .OptionsGroup .NotExists .componentFrontArrow svg path {
  fill: rgb(151, 227, 196);
  stroke: #fff;
}
.OptionsGroup .Optional .componentFrontArrow svg rect, .OptionsGroup .NotExists .componentFrontArrow svg rect {
  fill: transparent;
}
.OptionsGroup .Optional span, .OptionsGroup .NotExists span {
  text-indent: 13px;
  background: rgb(151, 227, 196);
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}
.OptionsGroup .Optional input, .OptionsGroup .NotExists input {
  display: none;
}
.OptionsGroup .Optional:hover .componentBackArrow svg, .OptionsGroup .Optional.Enabled .componentBackArrow svg, .OptionsGroup .NotExists:hover .componentBackArrow svg, .OptionsGroup .NotExists.Enabled .componentBackArrow svg {
  height: 48px;
}
.OptionsGroup .Optional:hover .componentBackArrow svg path, .OptionsGroup .Optional.Enabled .componentBackArrow svg path, .OptionsGroup .NotExists:hover .componentBackArrow svg path, .OptionsGroup .NotExists.Enabled .componentBackArrow svg path {
  fill: rgb(2, 184, 117);
  stroke: #fff;
}
.OptionsGroup .Optional:hover .componentFrontArrow svg, .OptionsGroup .Optional.Enabled .componentFrontArrow svg, .OptionsGroup .NotExists:hover .componentFrontArrow svg, .OptionsGroup .NotExists.Enabled .componentFrontArrow svg {
  height: 48px;
}
.OptionsGroup .Optional:hover .componentFrontArrow svg path, .OptionsGroup .Optional.Enabled .componentFrontArrow svg path, .OptionsGroup .NotExists:hover .componentFrontArrow svg path, .OptionsGroup .NotExists.Enabled .componentFrontArrow svg path {
  fill: rgb(2, 184, 117);
  stroke: #fff;
}
.OptionsGroup .Optional:hover .componentFrontArrow svg rect, .OptionsGroup .Optional.Enabled .componentFrontArrow svg rect, .OptionsGroup .NotExists:hover .componentFrontArrow svg rect, .OptionsGroup .NotExists.Enabled .componentFrontArrow svg rect {
  fill: transparent;
}
.OptionsGroup .Optional:hover span, .OptionsGroup .Optional.Enabled span, .OptionsGroup .NotExists:hover span, .OptionsGroup .NotExists.Enabled span {
  background: rgb(2, 184, 117);
}

.ObjectPropertyGroup {
  margin-right: -15px;
  padding-left: 10px;
}
.ObjectPropertyGroup .current {
  font-style: italic;
}
.ObjectPropertyGroup .temporary-label {
  line-height: 46px;
  font-size: 14px;
  height: 42px;
  padding-left: 20px;
  padding-right: 25px;
  display: inline-block;
  color: #666;
}

.ObjectPropertyTypeId {
  display: flex;
}

.StartClassGroup,
.EndClassGroup {
  position: relative;
  margin-right: 9px;
}
.StartClassGroup .selectViewVariableBtn,
.EndClassGroup .selectViewVariableBtn {
  position: absolute;
  top: 50%;
  margin-left: 112px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  z-index: 2;
  cursor: pointer;
}
.StartClassGroup .selectViewVariableBtn svg,
.EndClassGroup .selectViewVariableBtn svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.StartClassGroup.Highlited .componentFrontArrow, .ClassTypeId.Highlited .componentFrontArrow {
  margin-right: 5px;
}

.EndClassGroup {
  position: relative;
  min-width: auto;
  display: flex;
}
.EndClassGroup .EditComponents {
  position: absolute;
  left: 36px;
  top: 51px;
  line-height: 35px;
  z-index: 101;
  height: 54px;
  padding: 0 0;
  width: auto;
  font-size: 14px;
}
.EndClassGroup .EditComponents .edit-trait {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  border-top: 3px solid rgb(250, 136, 3);
  display: inline-block;
}
.EndClassGroup .EditComponents .edit-trait .edit-trait-top {
  position: absolute;
  bottom: 3px;
  left: -2px;
  display: none;
  border-left: 3px solid rgb(250, 136, 3);
  height: 24px;
}
.EndClassGroup .EditComponents .edit-num {
  position: absolute;
  top: -2px;
  left: -1px;
  transform: translate(-50%, -50%);
  border: 3px solid rgb(250, 136, 3);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 14px;
  font-size: 10px;
  background: #fff;
  font-style: italic;
}

.ClassTypeId {
  display: flex;
}
.ClassTypeId span.unselect {
  position: relative;
  top: -10px;
  right: 29px;
}
.ClassTypeId.Highlited .componentBackArrow svg {
  height: 48px;
}
.ClassTypeId.Highlited .componentBackArrow svg path {
  fill: rgb(250, 136, 3);
  stroke: #fff;
}
.ClassTypeId.Highlited .componentFrontArrow svg {
  height: 48px;
}
.ClassTypeId.Highlited .componentFrontArrow svg path {
  fill: rgb(250, 136, 3);
  stroke: #fff;
}
.ClassTypeId.Highlited .componentFrontArrow svg rect {
  fill: transparent;
}
.ClassTypeId.Highlited .nice-select {
  border-radius: unset;
  color: #fff;
  background-color: rgb(250, 136, 3);
  border-color: #fff;
}
.ClassTypeId.Highlited.VariableSelected .componentFrontArrow svg {
  height: 48px;
}
.ClassTypeId.Highlited.VariableSelected .componentFrontArrow svg path {
  fill: rgb(250, 136, 3);
  stroke: rgb(2, 184, 117);
}
.ClassTypeId.Highlited.VariableSelected .componentFrontArrow svg rect {
  fill: transparent;
}
.ClassTypeId.Highlited.VariableSelected .componentBackArrow svg {
  height: 48px;
}
.ClassTypeId.Highlited.VariableSelected .componentBackArrow svg path {
  fill: rgb(250, 136, 3);
  stroke: rgb(2, 184, 117);
}
.ClassTypeId.Highlited.VariableSelected .nice-select {
  border-color: rgb(2, 184, 117);
}

.ActionsGroup {
  position: absolute;
  display: block;
  top: 100%;
  width: 100%;
  height: 46px;
  z-index: 1;
}
.ActionsGroup:hover .ActionAnd {
  display: block;
}
.ActionsGroup > div {
  position: absolute;
}
.ActionsGroup .ActionAnd {
  display: none;
  left: 15px;
  left: 36px;
  top: 15px;
  line-height: 46px;
  /*width: 46px;*/
  text-align: center;
}
.ActionsGroup .ActionAnd .trait-and-bottom {
  position: absolute;
  top: -14px;
  left: -1px;
  border-left: 3px solid rgb(250, 136, 3);
  margin-left: -1px;
  height: 15px;
}
.ActionsGroup .ActionAnd .trait-and-bottom:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -6px;
  width: 9px;
  height: 9px;
  -moz-box-shadow: 0px 6px 3px -3px #cfcfcf;
  -webkit-box-shadow: 0px 6px 3px -3px #cfcfcf;
  -o-box-shadow: 0px 6px 3px -3px #cfcfcf;
  box-shadow: 0px 6px 3px -3px #cfcfcf;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=180, Strength=3);
}
.ActionsGroup .ActionAnd a {
  cursor: pointer;
  display: block;
  background: #fff;
  border: 2px solid rgb(250, 136, 3);
  border-radius: 15px;
  height: 30px;
  line-height: 20px;
  padding: 2px 10px;
  transform: translate(-50%, 0);
}
.ActionsGroup .ActionAnd a:hover {
  color: rgb(250, 136, 3);
}

.ActionWhere {
  line-height: 26px;
  position: absolute;
  display: flex;
  margin-top: -4px;
  border-left: 3px solid transparent;
}
.ActionWhere div {
  margin-left: 25px;
  width: max-content;
}
.ActionWhere a {
  position: absolute;
  left: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid rgb(250, 136, 3);
  border-radius: 13px;
  height: 26px;
  min-width: 26px !important;
  font-size: 12px;
  color: rgb(250, 136, 3) !important;
  font-weight: bold;
  margin-left: 10px;
}
.ActionWhere a:hover {
  color: rgb(250, 136, 3);
}

span.unselect {
  cursor: pointer;
  color: rgb(182, 97, 0);
  position: absolute;
  top: 12px;
  right: 25px;
  border-radius: 100%;
}
span.unselect i {
  background-color: white;
  border-radius: 100%;
}

.optionalArrow {
  height: 48px;
  width: 34px;
  margin-right: -20px;
  position: relative;
  cursor: pointer;
}
.optionalArrow svg {
  stroke: rgb(230, 230, 230);
  stroke-width: 3px;
  fill: rgb(107, 215, 171);
  stroke-width: 2.5px;
  position: absolute;
  z-index: 1;
  left: -7px;
}
.optionalArrow svg path {
  filter: url(#filter19278);
}

#icon_reset {
  fill: none;
  stroke: #1d1d1b;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.StartClassGroup .selectViewVariableBtn {
  color: #fff;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 16px;
  margin-top: -8px;
  z-index: 2;
  cursor: pointer;
}
.StartClassGroup .selectViewVariableBtn svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.EndClassGroup .selectViewVariableBtn {
  color: #fff;
  position: absolute;
  top: 50%;
  margin-left: 68%;
  height: 16px;
  margin-top: -8px;
  z-index: 2;
  cursor: pointer;
}
.EndClassGroup .selectViewVariableBtn svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.button-add {
  border: 3px solid rgb(250, 136, 3);
  border-radius: 15px;
  background: #fff;
  padding: 0 10px;
  line-height: 25px;
  margin-left: 5px;
}
.button-add:hover {
  color: rgb(250, 136, 3);
  cursor: pointer;
}

.circle-info {
  margin-left: 5px;
}

.AddWidgetValueBtn {
  display: flex;
  color: black;
  font-size: 20px;
  cursor: pointer;
  vertical-align: middle;
  max-width: 220px;
  height: 48px;
  margin-right: -13px;
  position: relative;
  padding: 0;
  /* Not sure what this does */
}
.AddWidgetValueBtn .componentBackArrow svg {
  height: 48px;
}
.AddWidgetValueBtn .componentBackArrow svg path {
  fill: rgb(255, 210, 157);
  stroke: #fff;
}
.AddWidgetValueBtn .componentFrontArrow svg {
  height: 48px;
}
.AddWidgetValueBtn .componentFrontArrow svg path {
  fill: rgb(255, 210, 157);
  stroke: #fff;
}
.AddWidgetValueBtn .componentFrontArrow svg rect {
  fill: transparent;
}
.AddWidgetValueBtn p {
  height: inherit;
  background: rgb(255, 210, 157);
  padding: 0 0 0 3px;
  border: 3px solid #fff;
  border-left: 0;
  border-right: 0;
  width: 0px;
  overflow: visible;
  position: relative;
}
.AddWidgetValueBtn p span {
  line-height: 42px;
  color: #666;
}
.AddWidgetValueBtn span.label-two-line {
  line-height: 24px;
  line-height: 21px;
}

.EndClassWidgetGroup {
  width: auto;
  padding: 0 15px 0 0;
  margin-left: -43px;
  display: flex;
}
.EndClassWidgetGroup span.unselect {
  top: -10px;
}

.EndClassWidgetValue {
  position: relative;
  display: flex;
  /*line-height: 0;*/
  color: black;
  font-size: 14px;
  /*display: inline-block;*/
  padding: 0;
  max-width: 220px;
  height: 46px;
  margin-right: -13px;
}
.EndClassWidgetValue .componentBackArrow svg {
  height: 48px;
}
.EndClassWidgetValue .componentBackArrow svg path {
  fill: rgb(255, 210, 157);
  stroke: #fff;
}
.EndClassWidgetValue .componentFrontArrow svg {
  height: 48px;
}
.EndClassWidgetValue .componentFrontArrow svg path {
  fill: rgb(255, 210, 157);
  stroke: #fff;
}
.EndClassWidgetValue .componentFrontArrow svg rect {
  fill: transparent;
}
.EndClassWidgetValue span.label-two-line {
  line-height: 24px;
  line-height: 21px;
}
.EndClassWidgetValue p {
  line-height: 48px;
  height: 48px;
  background: rgb(255, 210, 157);
  background-clip: padding-box;
  margin: 0;
  padding: 0 5px 0 5px;
  display: inline-block;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  border: 3px solid #fff;
  border-left: 0;
  border-right: 0;
  text-align: center;
}
.EndClassWidgetValue p span {
  line-height: 42px;
  display: inline-block;
  vertical-align: baseline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
  width: 100%;
}

.nb-choice-2 .WidgetWrapper {
  border-left: 3px solid rgb(250, 136, 3);
}
.nb-choice-2 .WidgetWrapper:before {
  display: none;
}

.WidgetWrapper {
  position: relative;
  height: 51px;
  padding-bottom: 3px;
  padding-left: 25px;
  padding-right: 5px;
  line-height: 48px;
  /*overflow: hidden;*/
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
  /*text-decoration: underline;*/
  /*&.DatesWidget {
      border-left-color: transparent;

      .edit-trait-top {
          display: block;
      }
  }*/
}
.WidgetWrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -3px;
  width: 3px;
  height: 20px;
  background: rgb(250, 136, 3);
}
.WidgetWrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -6px;
  width: 9px;
  height: 9px;
  -moz-box-shadow: 0px 6px 3px -3px #cfcfcf;
  -webkit-box-shadow: 0px 6px 3px -3px #cfcfcf;
  -o-box-shadow: 0px 6px 3px -3px #cfcfcf;
  box-shadow: 0px 6px 3px -3px #cfcfcf;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=180, Strength=3);
}
.WidgetWrapper .underline {
  text-decoration: underline;
}
.WidgetWrapper .boolean-value {
  text-decoration: underline;
  cursor: pointer;
}
.WidgetWrapper .or {
  color: rgb(250, 136, 3);
  font-weight: 600;
}
.WidgetWrapper .selectAll .underline {
  cursor: pointer;
}
.WidgetWrapper > a, .WidgetWrapper > div {
  position: absolute;
  left: 100%;
  top: 0;
}
.WidgetWrapper > .listwidget > div, .WidgetWrapper > .listwidget > input, .WidgetWrapper > .search-widget > div, .WidgetWrapper > .search-widget > input, .WidgetWrapper > .timedatepicker-widget > div, .WidgetWrapper > .timedatepicker-widget > input {
  display: inline-block;
}
.WidgetWrapper > .listwidget > input, .WidgetWrapper > .search-widget > input, .WidgetWrapper > .timedatepicker-widget > input {
  height: 35px;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  color: #555;
  float: none;
  padding: 6px 12px;
}
.WidgetWrapper > .listwidget > input.day, .WidgetWrapper > .search-widget > input.day, .WidgetWrapper > .timedatepicker-widget > input.day {
  width: 105px;
}
.WidgetWrapper > .listwidget > input.year, .WidgetWrapper > .search-widget > input.year, .WidgetWrapper > .timedatepicker-widget > input.year {
  width: 60px;
}
.WidgetWrapper > .date-widget > .easy-autocomplete {
  width: 176px !important;
}
.WidgetWrapper > .date-widget > .easy-autocomplete .easy-autocomplete-container ul li {
  position: relative;
  width: 296px;
  padding-right: 120px;
}
.WidgetWrapper > .date-widget > .easy-autocomplete .easy-autocomplete-container ul li span {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 60px;
  line-height: 20px;
  text-align: left;
  padding: 5px 12px;
  font-size: 14px;
}
.WidgetWrapper > .date-widget > .easy-autocomplete .easy-autocomplete-container ul li span.start {
  right: 60px;
}
.WidgetWrapper > .date-widget input {
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  color: #555;
  float: none;
  padding: 6px 10px;
}
.WidgetWrapper > .search-widget > input {
  width: 150px;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  color: #555;
  float: none;
  padding: 6px 12px;
}
.WidgetWrapper .easy-autocomplete-container > ul > li {
  white-space: normal;
}
.WidgetWrapper .SearchWidget,
.WidgetWrapper .DatesWidget {
  max-width: 600px;
  white-space: nowrap;
}
.WidgetWrapper .SearchWidget > div, .WidgetWrapper .SearchWidget > input,
.WidgetWrapper .DatesWidget > div,
.WidgetWrapper .DatesWidget > input {
  display: inline-block;
}
.WidgetWrapper .SearchWidget > input,
.WidgetWrapper .DatesWidget > input {
  width: 70px;
  height: 48px;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  color: #555;
  float: none;
  padding: 6px 12px;
}
.WidgetWrapper .SearchWidget .easy-autocomplete > input,
.WidgetWrapper .DatesWidget .easy-autocomplete > input {
  width: 100%;
}
.WidgetWrapper .SearchWidget > input {
  width: 200px;
}
.WidgetWrapper .ListeWidget .nice-select {
  background: #fff;
  height: 35px;
  line-height: 29px;
  padding-right: 26px;
}
.WidgetWrapper .ListeWidget .nice-select .current {
  display: inline-block;
  height: 29px;
  line-height: 29px;
  vertical-align: bottom;
}
.WidgetWrapper .ListeWidget .nice-select .current > span {
  vertical-align: bottom;
}
.WidgetWrapper .ListeWidget .nice-select .list li.option {
  min-height: 35px;
  line-height: 35px;
}
.WidgetWrapper .ListeWidget .nice-select:after {
  margin-top: -2px;
}

.map-widget {
  display: flex;
}
.map-widget #map {
  z-index: 3;
  height: 480px;
  width: 600px;
}

.tree-widget {
  height: 100%;
}
.tree-widget .treeBtnDisplay {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px #cfcfcf;
  padding: 5px;
  line-height: 1;
  z-index: 1;
  cursor: pointer;
}
.tree-widget .treeBtnDisplay img {
  height: 26px;
}
.tree-widget .treeLayer {
  display: none;
  position: absolute;
  top: 50%;
  margin-top: -19px;
  left: 95%;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px #cfcfcf;
  z-index: 2;
}
.tree-widget .treeLayer .treeClose {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 22px;
  height: 22px;
  vertical-align: top;
  line-height: 22px;
  cursor: pointer;
}
.tree-widget .treeLayer .treeClose i {
  text-align: center;
  color: rgb(182, 97, 0);
  background: #fff !important;
  font-size: 18px;
}
.tree-widget .treeLayer .treeNotice {
  padding: 5px;
  display: none;
  line-height: 20px;
}
.tree-widget .treeLayer .treeDisplay {
  overflow: auto;
  max-height: 500px;
  min-width: 275px;
  padding: 25px 10px 5px;
}
.tree-widget .treeLayer .treeDisplay.jstree-default .jstree-disabled {
  opacity: 0.5;
}
.tree-widget .treeLayer .treeDisplay.jstree-default .jstree-checkbox-disabled {
  opacity: 0.5;
}
.tree-widget .treeLayer .treeActions {
  border-top: 2px solid #ddd;
  padding: 5px 10px;
  text-align: right;
}
.tree-widget .treeLayer .treeActions a {
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin-left: 10px;
  padding: 15px 5px;
  line-height: 1;
  cursor: pointer;
}
.tree-widget .treeLayer .treeActions a.treeCancel {
  background: #EE8D67;
}
.tree-widget .treeLayer .treeActions a.treeSubmit {
  background: #84C99E;
}

.link-and-bottom {
  position: absolute;
  background-color: rgb(250, 136, 3);
  height: 3px;
  z-index: 0;
}
.link-and-bottom span {
  transform: rotate(-90deg);
  display: block;
  background: #fff;
  position: relative;
  left: 8px;
  top: -12px;
  border: 2px solid rgb(250, 136, 3);
  border-radius: 15px;
  height: 30px;
  line-height: 20px;
  padding: 2px 10px;
  width: fit-content;
}
.link-and-bottom.optionalEnabled {
  height: 3px;
  position: absolute;
  background: repeating-linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 8px, rgb(250, 136, 3) 8px, rgb(250, 136, 3) 16px);
  display: block;
}
.link-and-bottom.optionalEnabled span {
  border: 2px dashed rgb(250, 136, 3);
}
.link-and-bottom.notExistsEnabled {
  background: repeating-linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 8px, #000 8px, #000 16px);
}
.link-and-bottom.notExistsEnabled span {
  border: 3px dashed #000;
  border-radius: 15px;
}

.link-where-bottom .upper-vertical, .link-where-bottom .horizontal, .link-where-bottom .lower-vertical {
  height: 3px;
  position: absolute;
  background-color: rgb(250, 136, 3);
  display: block;
  z-index: 0;
}
.link-where-bottom .horizontal {
  z-index: 1;
}
.link-where-bottom .horizontal span {
  position: relative;
  top: -10px;
  left: -35px;
  background: #fff;
  border: 2px solid rgb(250, 136, 3);
  border-radius: 15px;
  height: 30px;
  line-height: 20px;
  padding: 2px 10px;
  width: fit-content;
}
.link-where-bottom.optionalEnabled .upper-vertical, .link-where-bottom.optionalEnabled .horizontal, .link-where-bottom.optionalEnabled .lower-vertical {
  height: 3px;
  position: absolute;
  background: repeating-linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 8px, rgb(250, 136, 3) 8px, rgb(250, 136, 3) 16px);
  display: block;
}
.link-where-bottom.optionalEnabled span {
  border: 2px dashed rgb(250, 136, 3);
}
.link-where-bottom.notExistsEnabled .upper-vertical, .link-where-bottom.notExistsEnabled .horizontal, .link-where-bottom.notExistsEnabled .lower-vertical {
  background: repeating-linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 8px, #000 8px, #000 16px);
}
.link-where-bottom.notExistsEnabled span {
  border: 3px dashed #000;
  border-radius: 15px;
}

/*Hide select widget list arrow*/
.nice-select.disabled:after {
  display: none;
}

.nice-select .current {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  vertical-align: bottom;
}
.nice-select .current span {
  display: inline-block;
  vertical-align: bottom;
  min-width: 20px;
}
.nice-select .current .selectIcon {
  float: left;
  width: 26px;
  text-align: center;
  margin-left: 5px;
}
.nice-select .current .variableName {
  display: none;
}

.nice-select {
  height: 48px;
  background: transparent;
  border-color: transparent;
  padding-left: 10px;
  padding-right: 26px;
  border-width: 0;
  border-top-width: 3px;
  border-bottom-width: 3px;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}
.nice-select.open, .nice-select:active, .nice-select:focus, .nice-select:hover {
  border-color: #fff;
}
.nice-select ul.list li {
  border-bottom: solid 1px #e8e8e8;
}
.nice-select ul.list li img {
  height: 40px;
}
.nice-select ul.list li img.highlited {
  display: none;
}
.nice-select ul.list li.selected, .nice-select ul.list li.focus, .nice-select ul.list li:hover {
  background: rgb(250, 136, 3);
  color: #fff;
}
.nice-select ul.list li.selected img, .nice-select ul.list li.focus img, .nice-select ul.list li:hover img {
  display: none;
}
.nice-select ul.list li.selected img.highlited, .nice-select ul.list li.focus img.highlited, .nice-select ul.list li:hover img.highlited {
  display: inline;
}
.nice-select ul.list {
  background: #fff;
  top: 0;
  margin-top: 0;
  max-height: 450px;
  overflow-y: auto;
}
.nice-select ul.list:hover .option:not(:hover) {
  color: #000;
}
.nice-select ul.list:hover .option:not(:hover) img {
  display: inline;
}
.nice-select ul.list:hover .option:not(:hover) img.highlited {
  display: none;
}
.nice-select .current img {
  height: 40px;
}
.nice-select .current img.highlited {
  display: none;
}
.nice-select:after {
  margin-top: 0;
}

.nice-select.disabled {
  color: #666;
  border-color: transparent;
  border-radius: 0;
}

.StartClassGroup .ClassTypeId .nice-select {
  border-left: 3px solid #fff;
}
.StartClassGroup .ClassTypeId.Highlited .nice-select {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  /* removes this otherwise green left border does not appear */
  /* border-left: 3px solid #fff; */
}

/**
 * Color of the criteria arrows (orange)
 * primary-color-main
 **/
/**
 * Color of the "unselect" buttons in criterias (dark orange)
 * primary-color-important
 **/
/**
 * Color of a selected value in a criteria (light orange)
 * primary-color-medium
 **/
/**
 * variable selector background & tooltip background (even lighter orange)
 * primary-color-light
 **/
/** 
 * Color of the options arrow (optional / negation) (strong green)
 * secondary-color-main
 **/
/**
 * Color of the options arrow when not selected (light green)
 * secondary-color-light
 **/
/** 
 * Color of the highlight of arrows
 * secondary-color-important
 **/
/**
 * Color of variable d&d grip, disabled query button, disabled options
 **/
/**
 *
 **/
/**
 * Color of line between query and variable selection
 **/
/**
 * Default text color
 **/
spar-natural section {
  padding-top: 30px;
}
spar-natural .Sparnatural {
  width: 100%;
}
spar-natural .Sparnatural .easy-autocomplete input {
  height: 35px;
  box-sizing: border-box;
}
spar-natural .Sparnatural .easy-autocomplete-container {
  z-index: 999;
}
spar-natural .Sparnatural .easy-autocomplete-container ul {
  line-height: 20px;
  background: #fff;
}
spar-natural .Sparnatural .easy-autocomplete-container ul li {
  padding: 5px;
}
spar-natural .Sparnatural .easy-autocomplete-container ul li.selected {
  background: rgb(250, 136, 3);
}

body .select2-container--default {
  font-size: 14px;
  line-height: 1.2;
}
body .select2-container--default .select2-results__option--highlighted.select2-results__option--highlighted {
  background-color: rgb(250, 136, 3);
}

.tippy-box[data-theme~=sparnatural] {
  background-color: rgb(255, 231, 203);
  color: #000;
  border-radius: 5px;
  border: 1px dashed rgb(250, 136, 3);
  padding: 2px;
  font-size: 0.75em;
}