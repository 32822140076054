@use 'sass:math';
@use './mixins';
@use './base';
@use './layout/all-layout.scss';
// Importing all the components
@use './components/all-components.scss';
@import "nice-select.css";
@import "~easy-autocomplete/dist/easy-autocomplete.css";
@import "~@chenfengyuan/datepicker/dist/datepicker.min.css";


@import "themes.scss";

spar-natural {
	section {
		padding-top: 30px;
	}	

	.Sparnatural {
		width:100%;
		.easy-autocomplete {
			input {
				height: base.$height-components-hover;
				box-sizing: border-box;
			}
		}
		.easy-autocomplete-container {
			z-index: 999;
			ul {
				line-height: 20px;
				background: #fff;
				li {
					padding: 5px;
					&.selected {
						
						background: $custom-red ;
					}
				}
			}
		}		
	}
}

//Select2 overides
body .select2-container--default {
	font-size: 14px;
	line-height: 1.2;
	.select2-results__option--highlighted.select2-results__option--highlighted {
		background-color: $custom-red;
	}
}
//tippy overides
.tippy-box[data-theme~='sparnatural'] {
    background-color: $custom-red-light;
    color: #000;
	border-radius: 5px;
	border: 1px dashed $custom-red;
	padding: 2px;
	font-size:0.75em;
  }