@use "../../../themes";
@use '../../../base';
@use '../../../mixins';
/*Hide select widget list arrow*/
.nice-select.disabled:after {
    display: none;
}
.nice-select .current {
    display: inline-block;
    height: base.$height-components-under - (base.$center-arrow-bd-width*2);
    line-height: base.$height-components-under - (base.$center-arrow-bd-width*2);
    vertical-align: bottom;
    span  {
        display: inline-block;
        vertical-align: bottom;
        min-width: 20px;
    }
    .selectIcon {
        float: left;
        width: 26px;
        text-align: center;
        margin-left: 5px;

    }
    .variableName {
        display: none;
    }
}

.nice-select {
    height: 48px;
    background: transparent;
    border-color: transparent;
    padding-left: 10px;
    padding-right: 26px;
    border-width: 0;
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    &.open, &:active, &:focus, &:hover {
        border-color: #fff;
    }
    ul.list li {
        border-bottom: solid 1px #e8e8e8;
        img {
            height : 40px;
            &.highlited {
                display: none;
            }
        }
        &.selected,
        &.focus,
        &:hover {
            background: themes.$custom-red ;
            color: #fff;
            img {
                display: none;
                &.highlited {
                    display: inline;
                }
            }
        }
    }
    ul.list {
        background: #fff;
        top: 0;
        margin-top: 0;
        max-height: 450px;
        overflow-y: auto;
    }
    ul.list:hover .option:not(:hover) {
        color: #000;
        img {
            display: inline;
            
            &.highlited {
                display: none;
            }
        }
    }
    .current {
        img {
            height: 40px;
            &.highlited {
                display: none;
            }
        }
    }
    &:after {
        margin-top: 0;
    }
}
.nice-select.disabled {
    color: themes.$default-text-color;
    border-color: transparent;
    border-radius: 0;
}

.StartClassGroup {
    .ClassTypeId .nice-select{
        border-left: 3px solid #fff;
    }
    .ClassTypeId.Highlited .nice-select{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        /* removes this otherwise green left border does not appear */
        /* border-left: 3px solid #fff; */
    }
}